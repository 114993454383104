export const SIMILAR_IMAGES_SEARCH_START = "SIMILAR_IMAGES_SEARCH_START";
export const SIMILAR_IMAGES_SEARCH_SUCCESS = "SIMILAR_IMAGES_SEARCH_SUCCESS";
export const SIMILAR_IMAGES_SEARCH_FAILURE = "SIMILAR_IMAGES_SEARCH_FAILURE";


export const CV_IMAGE_UPLOAD_START = "CV_IMAGE_UPLOAD_START";
export const CV_IMAGE_UPLOAD_SUCCESS = "CV_IMAGE_UPLOAD_SUCCESS";
export const CV_IMAGE_UPLOAD_FAILURE = "CV_IMAGE_UPLOAD_FAILURE";



export const CV_CLEAR_PREVIOUS_SEARCH = "CV_CLEAR_PREVIOUS_SEARCH";

