export const USER_PROFILE_PIC_UPDATE_START = "USER_PROFILE_PIC_UPDATE_START";
export const USER_PROFILE_PIC_UPDATE_SUCCESS = "USER_PROFILE_PIC_UPDATE_SUCCESS";
export const USER_PROFILE_PIC_UPDATE_FAILURE = "USER_PROFILE_PIC_UPDATE_FAILURE";


export const USER_ACCOUNT_UPDATE_START = "USER_ACCOUNT_UPDATE_START";
export const USER_ACCOUNT_UPDATE_SUCCESS = "USER_ACCOUNT_UPDATE_SUCCESS";
export const USER_ACCOUNT_UPDATE_FAILURE = "USER_ACCOUNT_UPDATE_FAILURE";



export const USER_FETCH_ACCOUNT_START = "USER_FETCH_ACCOUNT_START";
export const USER_FETCH_ACCOUNT_SUCCESS = "USER_FETCH_ACCOUNT_SUCCESS";
export const USER_FETCH_ACCOUNT_FAILURE = "USER_FETCH_ACCOUNT_FAILURE";