export const USER_SIGNUP_START = "USER_SIGNUP_START";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAILURE = "USER_SIGNUP_FAILURE";

export const USER_LOGIN_START = "USER_LOGIN_START";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";


export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";


export const USER_LOGOUT = "USER_LOGOUT";

export const APP_INIT_AUTO_LOGIN = "APP_INIT_AUTO_LOGIN";


export const HIDE_ONBOARDING_NOTIFICATION = "HIDE_ONBOARDING_NOTIFICATION";






